<template>
  <section class="q-pa-md q-gutter-sm">
    <h2>Component Test Page</h2>
    <q-separator />
    <Register :show-dialog="showRegister" @hide="showRegister = false" />

    <div class="row">
      <div class="col-4">
        Register
      </div>
      <div class="col-2">State: {{ showRegister }}</div>
      <div class="col-4">
        <q-btn
          @click="showRegister = !showRegister"
          label="Toggle Register Dialog"
        />
      </div>
    </div>
  </section>
</template>
<script>
import Register from '@/components/Register';
export default {
  name: 'RegistgerPage',
  components: { Register },

  data() {
    return {
      showRegister: false
    };
  }
};
</script>
